import request from './request';
import routes from './routes';

const campaignHeader = (headers) => ({'Authorization': 'Basic ' + btoa(headers?.id + ':' + headers?.hash)});

const api = {
    getApplicationStatus: () => request.jsonp(routes.getApplicationStatusUrl()),
    getApplicationRequestHeaders: () => request.head('/'),

    login: () => request.post(routes.getUserServiceUrl(`/login`)),
    getUserServiceResource: (resourceURI) => request.get(routes.getUserServiceResourceUrl(resourceURI)),
    getMe: () => request.get(routes.getUserServiceUrl('/me')),

    sendResetPasswordEmail: () => request.post(routes.getUserServiceUrl(`/resetPassword/sendEmail`)),
    validateResetPasswordToken: () => request.post(routes.getUserServiceUrl(`/resetPassword/validateToken`)),
    resetPasswordWithToken: () => request.post(routes.getUserServiceUrl(`/resetPassword`)),

    getClientConfigByClientHash: (clientHash, full = false) => request.get(routes.getClientConfigByHashUrl(clientHash, full)),

    logout: () => request.get(routes.getUserServiceLogoutUrl(true)),

    tokenAuthentication: () => request.post(routes.getUserServiceUrl(`/tokenAuthentication`)),
    verifyEmail: () => request.post(routes.getUserServiceUrl(`/verifyEmail`)),
    createAccount: () => request.post(routes.getUserServiceUrl('/create')),
    updateBasicData: (userId) => request.put(routes.getUserServiceUrl(`/${userId}`)),
    createRole: (userId) => request.post(routes.getUserServiceUrl(`/${userId}/role`)),

    getIdentityProviderSettingsByDomain: (domain) => request.post(routes.getUserServiceUrl(`/sso/${domain}/settings`)),

    updateUser: (userId, clientHash, role)=> request.put(routes.getUserServiceUrl(`/${userId}`)),
    setPassword: (userId) => request.put(routes.getUserServiceUrl(`/${userId}/setPassword`)),
    deleteShortTokenAuth: (userId) =>  request.delete(routes.getUserServiceUrl(`/${userId}/shortLivedToken`)),
    campaigns: {
        login: () => request.post(routes.getCampaignsServiceUrl('/user/login'), null, false),
        createBooking: (headers) => request.post(routes.getCampaignsServiceUrl(`/booking/create`), campaignHeader(headers), false),
    },
    client:{
        onboarding: ()=> request.post(routes.getClientConfigBaseUrl(`/clients/onboarding`)),
        emailVerification: (code, hcaptcha, secretKey) => request.post(routes.getClientConfigBaseUrl(`/clients/onboarding/email_verify?code=${code}${hcaptcha ? '&hcaptcha='+hcaptcha : ''}${secretKey ? '&secret='+secretKey : ''}`))
    },
}

export default api;
